var form_submitting = false;
var set_form_submitting = function( ) { form_submitting = true; };

$( document ).on( 'turbolinks:load', function( ) {
  console.log( 'in ( document ).on( turbolinks:load )' );
});

$( document ).ready( function( ) {
  console.log( 'in ( document ).ready( )' );
});


$( document ).ready( function( ) {
  // setup custom, attractive file upload widget, as per http://webapplayers.com/inspinia_admin-v2.9.3/ls_skin/form_file_upload.html
  $( '.custom-file-input' ).on( 'change', function( ) {
    let fileName = $( this ).val( ).split( '\\' ).pop( );
    $( this ).next( '.custom-file-label' ).addClass( 'selected' ).html( fileName );
  });

  $("[rel='tooltip']").tooltip();

  $( '.datepicker' ).datepicker({
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    autoclose: true,
    todayHighlight: true,
    immediateUpdates: true,
    showWeekDays: false
  })//.keyup( function( e ) { // This code was for blocking the user from utilizing backspace and delete key, cleaner method seems to be to set readonly attribute on the HTML input field
    //if( e.keyCode == 8 || e.keyCode == 46 ) {
    //    $( e.target ).datepicker( 'setDate', null );
    //}
  //});

  $( '.selectize' ).selectize({ });

  $( '.best_in_place' ).best_in_place( );

  $( '.bsc-dollar-mask' ).mask( '000,000,000,000,000,000,000', { reverse: true })
  $( '.bsc-dollar-cents-mask' ).mask( '000,000,000,000,000,000,000.00', { reverse: true })


  var form_has_changed = false;

  $( 'form[data-form-warning-unsaved=true]' ).each( ( i, form ) => {
    $( form ).submit( set_form_submitting );
  });

  $( 'form[data-form-warning-unsaved=true] :input' ).change( function( ) {
    form_has_changed = true;
  });

  window.addEventListener( 'beforeunload', function( e ) {
    if( form_submitting || ! form_has_changed ) {
      return undefined;
    }

    var confirmation_message = 'You have made changes to the form that have not been saved';

    ( e || window.event ).returnValue = confirmation_message;
    return confirmation_message;
  });
});
